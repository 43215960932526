import {
  Card,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Grid
} from "@mui/material";
import { HeadFC, Link } from "gatsby";
import moment from "moment";
import * as React from "react";
import { stripHtml } from "string-strip-html";
import { AdSense } from "../components/AdSense";
import { MainFrame } from "../components/MainFrame";
import { SocialButtons } from "../components/SocialButtons";
import "../styles/styles.scss";

const PostPage = ({
  data,
  pageContext
}: {
  data: any;
  pageContext: {
    node: Queries.MicrocmsBlogs;
    previous: Queries.MicrocmsBlogs;
    next: Queries.MicrocmsBlogs;
  };
}) => {
  const publishedAt = moment(pageContext.node?.publishedAt).format(
    "YYYY/MM/DD"
  );

  const richEditorContent = (
    pageContext?.node?.content?.map((x) => x?.richEditor || "").join("") || ""
  )
    .replaceAll(/src="(.+?)"/g, `src="$1?auto=compress&auto=format"`)
    .replaceAll(
      /(<img src="(.+?)".+?>)/g,
      `<a href="$2" target="_blank" rel="noopener noreferrer">$1</a>`
    )
    .replaceAll(/alt=""/g, `alt="${pageContext?.node?.title || ""}"`);

  const content = (
    <Card sx={{ width: "min(95vw)" }}>
      <CardMedia
        className="eyecatch"
        component="img"
        image={
          pageContext?.node?.eyecatch?.url
            ? `${pageContext?.node?.eyecatch.url}${
                pageContext?.node?.eyecatch.url.includes("?") ? "&" : "?"
              }auto=compress&auto=format`
            : undefined
        }
      />
      <CardContent>
        <h2>{pageContext?.node?.title}</h2>
        <AdSense path={pageContext.node.blogsId || ""} />
        <div
          className="content"
          dangerouslySetInnerHTML={{
            __html: richEditorContent
          }}
        ></div>
      </CardContent>
      <CardContent>
        <div
          dangerouslySetInnerHTML={{
            __html:
              pageContext?.node?.content?.map((x) => x?.html || "").join("") ||
              ""
          }}
        ></div>
      </CardContent>
      <CardContent sx={{ backgroundColor: "#FAFAFA" }}>
        <AdSense path={pageContext.node.blogsId || ""} />
        <div>
          tags:
          {pageContext?.node?.tags?.map((x, index) => (
            <React.Fragment key={`tags-${index}`}>
              <Chip
                label={x?.name}
                component={Link}
                to={`/tags/${x?.id}/1`}
                clickable
                sx={{ margin: "1.5px" }}
              />
            </React.Fragment>
          ))}
        </div>
        <Divider sx={{ margin: "0.5em" }} />
        <div>published at: {publishedAt}</div>
        <Divider sx={{ margin: "0.5em" }} />
        <SocialButtons blogsId={pageContext.node.blogsId} />
      </CardContent>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs></Grid>
          {pageContext?.previous?.blogsId ? (
            <Grid item xs="auto">
              <span style={{ marginRight: "1em" }}>&lt;</span>
              <Link to={`/${pageContext.previous.blogsId}`}>
                {pageContext.previous.title}
              </Link>
            </Grid>
          ) : null}
          {pageContext?.next?.blogsId ? (
            <Grid item xs="auto">
              <Link to={`/${pageContext.next.blogsId}`}>
                {pageContext.next.title}
              </Link>
              <span style={{ marginLeft: "1em" }}>&gt;</span>
            </Grid>
          ) : null}
          <Grid item xs></Grid>
        </Grid>
      </CardContent>
    </Card>
  );

  const description = stripHtml(
    pageContext.node?.content?.map((x) => x?.richEditor || "").join("") || ""
  ).result.substring(0, 1000);

  return (
    <MainFrame
      type="article"
      subtitle={pageContext?.node?.title || undefined}
      description={description}
      imageUrl={pageContext?.node?.eyecatch?.url || undefined}
    >
      <Grid container>
        <Grid item xs></Grid>
        <Grid item xs="auto">
          {content}
        </Grid>
        <Grid item xs></Grid>
      </Grid>
    </MainFrame>
  );
};

export default PostPage;

export const Head: HeadFC = () => <></>;
