import { Grid } from "@mui/material";
import { graphql, useStaticQuery } from "gatsby";
import React from "react";
import {
  FacebookIcon,
  FacebookShareButton,
  HatenaIcon,
  HatenaShareButton,
  LineIcon,
  LineShareButton,
  TumblrIcon,
  TumblrShareButton,
  TwitterIcon,
  TwitterShareButton
} from "react-share";

export interface SocialButtonsProps {
  blogsId: string | null;
}

export const SocialButtons = (props: SocialButtonsProps) => {
  const { site }: { site: Queries.Site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            siteUrl
          }
        }
      }
    `
  );

  if (site.siteMetadata?.siteUrl && props.blogsId) {
    return (
      <>
        <TwitterShareButton
          style={{ margin: "1.5px" }}
          url={`${site.siteMetadata.siteUrl}/${props.blogsId}`}
        >
          <TwitterIcon round size={30} />
        </TwitterShareButton>
        <FacebookShareButton
          style={{ margin: "1.5px" }}
          url={`${site.siteMetadata.siteUrl}/${props.blogsId}`}
        >
          <FacebookIcon round size={30} />
        </FacebookShareButton>
        <LineShareButton
          style={{ margin: "1.5px" }}
          url={`${site.siteMetadata.siteUrl}/${props.blogsId}`}
        >
          <LineIcon round size={30} />
        </LineShareButton>
        <TumblrShareButton
          style={{ margin: "1.5px" }}
          url={`${site.siteMetadata.siteUrl}/${props.blogsId}`}
        >
          <TumblrIcon round size={30} />
        </TumblrShareButton>
        <HatenaShareButton
          style={{ margin: "1.5px" }}
          url={`${site.siteMetadata.siteUrl}/${props.blogsId}`}
        >
          <HatenaIcon round size={30} />
        </HatenaShareButton>
      </>
    );
  }

  return null;
};
