import React, { useEffect } from "react";

export interface AdSenseProps {
  path: string;
}

export const AdSense = (props: AdSenseProps) => {
  useEffect(() => {
    ((window as any).adsbygoogle = (window as any).adsbygoogle || []).push({});
  }, [props.path]);

  return (
    <div className="adsense-wrapper">
      <ins
        className="adsbygoogle"
        style={{ display: "block" }}
        data-ad-client="ca-pub-3234735716706297"
        data-ad-slot="1476591718"
        data-ad-format="auto"
        data-full-width-responsive="true"
      ></ins>
    </div>
  );
};
